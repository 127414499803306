<template>
  <div v-if="loadedItems">
    <div>
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }"
          >
            <template v-slot:body>

                            <span class="topcardtitle">
                              Filtri e ricerca
                            </span>

              <b-button-toolbar
                  aria-label="Toolbar with button groups and input groups"
              >


                <div class="col-2">
                  <v-text-field style="max-width: 120px;" class="ml-3 textfilt textfiltup"
                                type="number"
                                v-model="filter.id"
                                label="ID"
                                single-line
                                hide-details
                                v-on:input="deReloadList()"
                  ></v-text-field>
                </div>

                <div class="col-2">
                  <v-text-field style="max-width: 120px;" class="ml-3 textfilt textfiltup"
                                type="number"
                                v-model="filter.contract_log_id"
                                label="Contract Log ID"
                                single-line
                                hide-details
                                v-on:input="deReloadList()"
                  ></v-text-field>
                </div>

                <div class="col-2">
                  <b-form-select
                      class="mb-1 mr-sm-1 mb-sm-0"
                      v-model="filter.organization"
                      :options="filteroptions.organization"
                      id="inline-form-custom-select-pref"
                      required
                      @change="reloadList()"
                  >
                    <template v-slot:first>
                      <option :value="null">Organizzazione</option>
                    </template>
                  </b-form-select>
                </div>
<!--                <div style="width:320px;float:left;">-->
<!--                  <vc-date-picker v-model="range" is-range :input-debounce="500"-->
<!--                                  style="    align-self: flex-end;">-->
<!--                    <template v-slot="{ inputValue, inputEvents }">-->
<!--                      <div class="flex justify-center items-center" style="">-->
<!--                        Data:-->
<!--                        <input style="max-width: 114px;"-->
<!--                               placeholder="Inizio"-->
<!--                               :value="inputValue.start"-->
<!--                               v-on="inputEvents.start"-->
<!--                               class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"-->
<!--                        />-->
<!--                        <input style="max-width: 114px;"-->
<!--                               placeholder="Fine"-->
<!--                               :value="inputValue.end"-->
<!--                               v-on="inputEvents.end"-->
<!--                               class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </vc-date-picker>-->
<!--                </div>-->
                <div class="col-4"  style="margin-left: auto">
                <b-button-group size="sm" class="mr-1" >
















<!--                  <b-form-select-->
<!--                      class="mb-2 mr-sm-2 mb-sm-0"-->
<!--                      v-model="filter.statussign"-->
<!--                      :options="filteroptions.statussign"-->
<!--                      id="inline-form-custom-select-pref"-->
<!--                      required-->
<!--                      @change="reloadList()"-->
<!--                  >-->
<!--                    <template v-slot:first>-->
<!--                      <option :value="null">Stato documento</option>-->
<!--                    </template>-->
<!--                  </b-form-select>-->

<!--                  <b-form-select-->
<!--                      class="mb-2 mr-sm-2 mb-sm-0"-->
<!--                      v-model="filter.statusinstantcall"-->
<!--                      :options="filteroptions.statusinstantcall"-->
<!--                      id="inline-form-custom-select-pref"-->
<!--                      required-->
<!--                      @change="reloadList()"-->
<!--                  >-->
<!--                    <template v-slot:first>-->
<!--                      <option :value="null">Stato bot</option>-->
<!--                    </template>-->
<!--                  </b-form-select>-->

                  <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="filter.statuseva"
                      :options="filteroptions.statuseva"
                      id="inline-form-custom-select-pref"
                      required
                      @change="reloadList()"
                  >
                    <template v-slot:first>
                      <option :value="null">Stato eva</option>
                    </template>
                  </b-form-select>








                </b-button-group>
                </div>
                <div class="col-2"  style="margin-left: auto">
                  <button @click="resetFilters()" class="btn btn-secondary" style="float: left;padding: 3px 10px;">
                    <v-icon
                        small
                        class="mr-2 vaction"

                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <small>Reset</small>
                  </button>
                </div>


              </b-button-toolbar>




            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>


      </div>
    </div>
<!--      <div>-->
<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          &lt;!&ndash;begin:: Widgets/Activity&ndash;&gt;-->
<!--          <KTPortlet-->
<!--              v-bind="{-->
<!--            }"-->
<!--          >-->
<!--            <template v-slot:body>-->

<!--                            <span class="topcardtitle">-->
<!--                              Totali-->
<!--                            </span>-->
<!--              <div class="row">-->
<!--            <div class="col-3">-->
<!--              <v-card>-->
<!--                <v-card-title  style="display: block">-->
<!--                  <span class="fs-2hx text-info fw-bold me-2 lh-1 ls-n2">69</span><br><span class="opacity-50 pt-1 fw-semibold fs-6">Pratiche totali</span>-->
<!--                </v-card-title>-->
<!--              </v-card>-->
<!--            </div>-->
<!--            <div class="col-3">-->
<!--              <v-card>-->
<!--                <v-card-title  style="display: block">-->
<!--                  <span class="fs-2hx text-success fw-bold me-2 lh-1 ls-n2">69</span><br><span class="opacity-50 pt-1 fw-semibold fs-6">Pratiche inserite</span>-->
<!--                </v-card-title>-->
<!--              </v-card>-->
<!--            </div>-->
<!--            <div class="col-3">-->
<!--              <v-card>-->
<!--                <v-card-title  style="display: block">-->
<!--                  <span class="fs-2hx text-danger fw-bold me-2 lh-1 ls-n2">69</span><br><span class="opacity-50 pt-1 fw-semibold fs-6">Pratiche bloccate</span>-->
<!--                </v-card-title>-->
<!--              </v-card>-->
<!--            </div>-->
<!--            <div class="col-3">-->
<!--              <v-card>-->
<!--                <v-card-title  style="display: block">-->
<!--                  <span class="fs-2hx text-warning fw-bold me-2 lh-1 ls-n2">69</span><br><span class="opacity-50 pt-1 fw-semibold fs-6">Pratiche in lavorazione</span>-->
<!--                </v-card-title>-->
<!--              </v-card>-->
<!--            </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </KTPortlet>-->
<!--          &lt;!&ndash;end:: Widgets/Activity&ndash;&gt;-->
<!--        </div>-->


<!--      </div>-->
<!--    </div>-->
    <div v-permission="'contract.view'">
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
          >
            <template v-slot:body>

              <v-card>
                <!--                                <v-card-title>-->
                <!--                                    Elenco-->
                <!--                                </v-card-title>-->
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :single-select="singleSelect"
                    :sort-by="pagination.sortBy"
                    :sort-desc="pagination.sortDesc"
                    multi-sortNO
                    item-key="id"
                    show-selectNO
                    class="elevation-1 table-force-width"
                    :search="search"
                    :footer-props="footerProps"
                    :server-items-length="pagination.totalNumberOfItems"
                    @update:page="paginate"
                    @update:sort-by="sortBy"
                    @update:sort-desc="sortDesc"
                >
                  <template v-slot:item.status="{ item }">
                    <span>{{item.status}}</span>
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    <span>{{item.created_at | formatDate}}</span>
                  </template>
                  <template v-slot:item.contract_log.organization_id="{ item }">
                    <span>{{organizations && typeof(organizations[item.contract_log.organization_id])!=='undefined' ? organizations[item.contract_log.organization_id].text : item.contract_log.organization_id}}</span>
                  </template>
                  <!--                  <template v-slot:item.updated_at="{ item }">-->
                  <!--                    <span>{{item.updated_at | formatDate}}</span>-->
                  <!--                  </template>-->
                  <!--                                    <template v-slot:top>
                                                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                      </template>-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2 vaction"
                            @click="viewSubmission(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
          <p v-if="this.search">Attenzione: la ricerca libera agisce solo sui risultati visualizzati.</p>
        </div>

      </div>
    </div>

  </div>
</template>
<style>
.table-force-width.v-data-table__wrapper table{
  max-width: 100%;
  table-layout: fixed;
}
.table-force-width.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.table-force-width.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.table-force-width.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.table-force-width.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.table-force-width.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.table-force-width.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px;
}
.textfilt{
  &.textfiltup{
    max-width: 100px;
    border: 1px solid #e2e5ec;
    margin: 0 auto;
    padding: 0;
  }
  .v-text-field__slot{
    height:35px;
  }
  label{
    left:10px !important;
    font-size: 13px;
  }
  input{
    padding: 8px 10px;
    font-size: 13px;
  }
}
</style>
<script>
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {API_URL} from "@/common/config";
import moment from 'moment';
import UserService from "../../../common/user.service";
// import ExcellentExport from 'excellentexport';
// import Excellentexport from "excellentexport/dist/excellentexport";


// http://localhost:8080/#/estra/edit/189516
export default {
  data() {
    return {
      loading: false,
      singleSelect: false,
      isDraft:false,
      showAgentColumns:false,
      showRandomColumn:false,
      showDownloadCSVButton:false,
      selected: [],
      organizations: [],
      search: '',
      contractNames:{},
      loadedItems:false,
      headers: null,
      range: {
        start: null,
        end: null
      },
      rangeExport: {
        start: null,
        end: null
      },
      pagination: {
        //descending: !!this.$route.query.desc,
        //sortBy: this.$route.query.orderby || 'name',
        rowsPerPage: 100,
        //listSize: [10, 25, 50, 100],
        listSize: [100],
        page: 1,
        totalNumberOfItems: 0,
        sortBy: ['id'],
        sortDesc: [true],
      },
      footerProps: {'items-per-page-options': [100]},
      filteroptions: {
        organization: [],
        contract: [],
        // statussign:[
        //   {
        //     value:'SIGNED',
        //     text:'SIGNED',
        //   },
        //   {
        //     value:'SENT',
        //     text:'SENT',
        //   },
        //   {
        //     value:'REJECTED',
        //     text:'REJECTED',
        //   }
        // ],
        // statusinstantcall:[
        //   {
        //     value:'OK',
        //     text:'OK',
        //   },
        //   {
        //     value:'NOK',
        //     text:'NOK',
        //   },
        //   {
        //     value:'READY',
        //     text:'READY',
        //   },
        //   {
        //     value:'SENT',
        //     text:'SENT',
        //   },
        //   {
        //     value:'FAILED',
        //     text:'FAILED',
        //   },
        // ],
        // statuscrm:[
        //   {
        //     value:'READY',
        //     text:'READY',
        //   },
        //   {
        //     value:'IGNORED',
        //     text:'IGNORED',
        //   },
        //   {
        //     value:'REJECTED',
        //     text:'REJECTED',
        //   }
        // ],
        statuseva:[
          {
            value:'READY',
            text:'READY',
          },
          {
            value:'FAILED',
            text:'FAILED',
          },
          {
            value:'SENT',
            text:'SENT',
          },
          {
            value:'IGNORED',
            text:'IGNORED',
          },
          {
            value:'NON INSERITO',
            text:'NON INSERITO',
          },
          {
            value:'INSERITO CON ATTIVATORE',
            text:'INSERITO CON ATTIVATORE',
          },
          {
            value:'RESPINTO',
            text:'RESPINTO',
          },
          {
            value:'RESPINTO DEFINITIVO',
            text:'RESPINTO DEFINITIVO',
          },
          {
            value:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
            text:'IN GESTIONE CONTRATTO DI DESTINAZIONE',
          }
        ]
      },
      filter: {
      },
      items: [],
    }
  },
  watch: {
    range: function () {
      this.reloadList();
    }
  },
  components: {
    KTPortlet
  },
  mounted() {



    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "CRM Esterni", route: "/ext-crm"},
      {title: "Lista", route: "list"}
    ]);

  },

  created() {
    this.resetFilters();

    this.loadOptions();
    this.loadOrganizations();
  },

  methods: {
    loadOrganizations(){
      ApiService.get('organizations').then(({ data }) => {
        data.rows.forEach((x) => {
          this.organizations[parseInt(x.id)] = {value: x.id, text: x.name};
          this.filteroptions.organization.push({value: x.id, text: x.id + ' - ' + x.name});
        })
            .catch(({ response }) => {
              //context.commit(SET_ERROR, response.data.errors);
            });
      })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    resetFilters(){
      this.filter = {
        // contract: null,
        // statussign: null,
        // statusinstantcall: null,
        // statuscrm: null,
        statuseva: null,
        organization: null,
      };
      // this.range.start = moment().subtract(1, 'months').toDate();
      // this.range.end = moment().toDate();
      this.range.start = null;
      this.range.end = null;
      this.search = '';
      this.reloadList();
    },
    loadOptions() {
      ApiService.get('contracts')
          .then(({data}) => {
            data.rows.forEach((x) => {
              this.contractNames[parseInt(x.id)] = x.label;
              this.filteroptions.contract.push({value: x.id, text: x.id + ' - ' + x.label});
            });

            this.loadedItems = true;
            this.initialize();
          })
          .catch(({response}) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    paginate(val) {
      this.pagination.page = val;
      this.reloadList();
    },
    sortBy(val) {
      this.pagination.sortBy = val;
      this.reloadList();
    },
    sortDesc(val) {
      this.pagination.sortDesc = val;
      this.reloadList();
    },
    getListParameters(){
      const params = {
        page: this.pagination.page,
      };
      // if(UtilService.isNumeric()){
      //
      // }
      if (this.filter.id) {
        params.id = parseInt(this.filter.id);
      }
      if (this.filter.contract_log_id) {
        params.contract_log_id = parseInt(this.filter.contract_log_id);
      }
      // if (this.range.start) {
      //   params.date_from = moment(this.range.start).format('YYYY-MM-DD');
      // }
      // if (this.range.end) {
      //   params.date_to = moment(this.range.end).format('YYYY-MM-DD');
      // }
      // if(this.filter.contract){
      //   params.contract_configuration_id = this.filter.contract;
      // }
      // if(this.filter.statussign){
      //   params.status_sign = this.filter.statussign;
      // }
      // if(this.filter.statusinstantcall){
      //   params.status_instant_call = this.filter.statusinstantcall;
      // }
      // if(this.filter.statuscrm){
      //   params.status_crm = this.filter.statuscrm;
      // }
      if(this.filter.organization){
        params.organization_id = this.filter.organization;
      }
      if(this.filter.statuseva){
        params.eva_status = this.filter.statuseva;
      }
      // if(this.search){
      //   params.search = this.search;
      // }
      // if(this.pagination.sortBy&&this.pagination.sortBy.length){
      //   params.order_by = this.pagination.sortBy[0];
      // }
      // if(this.pagination.sortDesc&&this.pagination.sortDesc.length){
      //   params.order_desc = this.pagination.sortDesc[0]?'desc':'asc';
      // }
      return params;
    },
    filterListItems(items){
      return items.filter((x) => {
        // Try to get data from submission
        let holderData = [];
        let additionalInfo = JSON.parse(x.eva_payload);
        if (additionalInfo) {
          if (additionalInfo.ragione_sociale) {
            holderData.push(additionalInfo.ragione_sociale);
          } else {
            if (additionalInfo.cognome) {
              holderData.push(additionalInfo.cognome);
            }
            if (additionalInfo.nome) {
              holderData.push(additionalInfo.nome);
            }
          }
        }
        x._holder = holderData.join(' ');
        // x.bot_meta = "{\"created_at\":\"2024-03-07T18:01:16.000Z\",\"ele_status\":\"CONTRACT_UPLOADED\",\"status\":\"SUCCESS\",\"ele_uploaded_at\":\"2024-03-11T12:54:25.520Z\",\"finished_at\":\"2024-03-11T12:54:25.561Z\",\"job_id\":\"189516\",\"idPratica\":\"4558836\",\"isDual\":false,\"screenshot_key\":\"neta_reports/202403/318288.zip\",\"error_at\":null,\"error_meta\":\"{\\\"error\\\":\\\"<p class=\\\\\\\"font-weight-bold\\\\\\\">Provenienza</p><p>FrontEnd</p><p class=\\\\\\\"font-weight-bold\\\\\\\">Descrizione</p><p>Si è verificato un errore nel caricamento del file. Si prega di riprovare.</p>\\\"}\",\"ele_saved_at\":\"2024-03-11T12:53:55.229Z\",\"error\":null}";

        x.botMeta = JSON.parse(x.bot_meta);




        // Text filter
        // if (this.search && (!x.id || x.id != this.search) && (!x._holder || x._holder.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.email || x.email.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.phone || x.phone.indexOf(this.search) === -1) && (!x.random_code || x.random_code.toLowerCase().indexOf(this.search.toLowerCase()) === -1) && (!x.codice_agente || x.codice_agente.toLowerCase().indexOf(this.search.toLowerCase()) === -1)) {
        //   return false;
        // }


        return true;
      });
    },
    deReloadList() {
      if (window.search_timeout) clearTimeout(window.search_timeout);
      var that = this;
      window.search_timeout = setTimeout(function () {
        that.reloadList();
      }, 800);
    },
    reloadList() {
      // https://github.com/solowebsrl/fastsignAPI/commit/294540b3d5be53fb5b941b4a2771eb2bf9f064fc
      // console.log('reloadinglist', this.range);
      this.loading = true;
      const params = this.getListParameters();


      const callbackList = ApiService.get('/bot-management/jobs', '?' + (new URLSearchParams(params)).toString())

      callbackList
          .then(({data}) => {

            this.pagination.totalNumberOfItems = data.count;
            this.items = this.filterListItems(data.rows);
            this.loading = false;
          })
          .catch(({response}) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    getHeaders(){
      let headers = [
            {text: 'ID Fastsign', value: 'id'},
            {text: 'ID Contract Log', value: 'contract_log_id'},
            {text: 'Organization', value: 'contract_log.organization_id'},
            {text: 'Created At', value: 'created_at'},
            {text: 'Holder', value: '_holder'},
            {text: 'Fast Status', value: 'status', sortable: false},
            {text: 'CRM Status', value: 'eva_status', sortable: false},
            {text: 'BOT Done At', value: 'bot_done_at'}, //botMeta.finished_at
            {text: 'Note', value: 'note', sortable: false},
            {text: 'ELE Status', value: 'botMeta.ele_status', sortable: false},
            {text: 'GAS Status', value: 'botMeta.gas_status', sortable: false},
            {text: 'BOT error', value: 'last_bot_error', sortable: false},
            {text: 'ELE Saved At', value: 'botMeta.ele_saved_at'},
            {text: 'ELE Uploaded At', value: 'botMeta.ele_uploaded_at'},
            {text: 'GAS Saved At', value: 'botMeta.gas_saved_at'},
            {text: 'GAS Uploaded At', value: 'botMeta.gas_uploaded_at'},
            {text: 'ID Neta', value: 'botMeta.idPratica', sortable: false},
            {text: 'ID Dual', value: 'botMeta.idPraticadual', sortable: false},
            {text: 'Dual', value: 'botMeta.isDual', sortable: false},

            {text: 'BOT Status', value: 'bot_status', sortable: false},

          ];
      headers = headers.filter(x => !!x);
      return headers;
    },
    initialize() {


      this.headers = this.getHeaders();


      if (this.$route.query.filter_contract) {
        this.filter.contract = this.$route.query.filter_contract;
      }

      // if(UserService.hasPermission(['contract.edit'])){
      this.headers.push({text: 'Actions', value: 'actions', sortable: false, width: '150px'});
      // }
      this.reloadList();

      // this.showDownloadCSVButton = this.showDownloadCSV();

    },

    // editSubmission (item) {
    //   this.$router.push({ name: "document-submission-edit", 'params':{'id':item.id} });
    // },
    viewSubmission(item) {
      this.$router.push({
        name: "ext-crm-edit",
        'params': {'id': item.id}
      });
    },

    // deleteDraft(item) {
    //   ApiService.delete('drafts/'+item.id,{'id':item.id}).then(() => this.reloadList());
    // },



  },
};
</script>
